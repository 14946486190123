<template>
  <div class="home">
    <loading :active.sync="isLoading"></loading>

    <Header />

    <div class="content">
      <router-view />
    </div>

    <div class="footer">
      <!-- <p>Design by <a href="http://w3layouts.com/">W3layouts</a></p> -->
    </div>
  </div>
</template>
<script>
$(document).ready(function () {
  //Hide (Collapse) the toggle containers on load
})
</script>

<script>
import '../assets/css/style.css'
import '../assets/css/main.css'
import mix from './mixinData.vue'
import Bus from './Bus.js'
import Header from './Header.vue'
export default {
  mixins: [mix],
  name: 'Home',
  data() {
    return {
      isLoading: false,
      inputSearch: '',
    }
  },
  components: {
    Header,
  },
  //   mounted() {
  //     $('.toggle_container').hide()
  //     //Switch the "Open" and "Close" state per click then slide up/down (depending on open/close state)
  //     $('.trigger').click(function () {
  //       $(this).toggleClass('active').next().slideToggle('slow')
  //       return false //Prevent the browser jump to the link anchor
  //     })

  //     $('.fromData').css('visibility', 'hidden')
  //     $('#box').animate({ top: '-700px' }, 500)
  //   },
  //   methods: {
  //     activator() {
  //       console.log('hi')
  //       $('#box').animate({ top: '0px' }, 500)
  //     },
  //     boxclose() {
  //       $('#box').animate({ top: '-700px' }, 500)
  //     },
  //     getSearch() {
  //       console.log('home input', this.inputSearch)
  //       Bus.$emit('input', this.inputSearch)
  //       this.inputSearch = ''
  //     },
  //   },
  created() {},
}
</script>
