<template>
  <div class="">
    <!-- <loading :active.sync="isLoading"></loading> -->
    <!---start-wrap---->
    <!---start-header---->
    <div class="header">
      <div class="wrap">
        <div class="logo">
          <router-link to="/">
            <img src="../assets/img/logoS.jpg" title="EMBA" />
            <!-- <img src="../assets/img/logo.png" title="EMBA" /> -->
            <!-- <div v-if="loading">Loading....</div>
            <div v-else>OK</div> -->
          </router-link>
        </div>
        <div class="nav-icon">
          <a href="#" class="right_bt" id="activator" @click="activator()"
            ><span> </span>
          </a>
        </div>
        <div class="box" id="box">
          <div class="box_content">
            <div class="box_content_center">
              <div class="form_content">
                <div class="menu_box_list">
                  <ul>
                    <li @click="boxclose()">
                      <a
                        href="https://sites.google.com/emba.ntu.edu.tw/society/%E9%A6%96%E9%A0%81/%E9%97%9C%E6%96%BC%E7%A6%8F%E5%88%A9%E7%A4%BE?authuser=2"
                        target="_blank"
                      >
                        <span>關於福利社</span>
                      </a>
                    </li>
                    <li @click="boxclose()">
                      <a
                        href="https://sites.google.com/emba.ntu.edu.tw/society/%E9%A6%96%E9%A0%81/%E7%A6%8F%E5%88%A9%E7%A4%BE%E9%83%A8%E8%90%BD%E6%A0%BC?authuser=2"
                        target="_blank"
                      >
                        <span>部落格文章</span>
                      </a>
                    </li>
                    <li @click="boxclose()">
                      <router-link to="/">
                        <span>所有活動</span>
                      </router-link>
                    </li>

                    <div class="clear"></div>
                  </ul>
                </div>
                <a class="boxclose" id="boxclose" @click="boxclose()">
                  <span> </span
                ></a>
              </div>
            </div>
          </div>
        </div>
        <div class="top-searchbar">
          <form>
            <input type="text" value="" v-model="inputSearch" /><input
              type="submit"
              value=""
              @click="getSearch()"
            />
          </form>
        </div>
        <div class="userinfo">
          <div class="user"></div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
    <!---//End-header---->

    <!---//End-wrap---->
  </div>
</template>
<script>
$(document).ready(function () {
  //Hide (Collapse) the toggle containers on load
})
</script>

<script>
import '../assets/css/style.css'
import '../assets/css/main.css'
import mix from './mixinData.vue'
import Bus from './Bus.js'
export default {
  mixins: [mix],
  name: 'Home',
  data() {
    return {
      isLoading: false,
      inputSearch: '',
    }
  },
  components: {},
  mounted() {
    $('.toggle_container').hide()
    //Switch the "Open" and "Close" state per click then slide up/down (depending on open/close state)
    $('.trigger').click(function () {
      $(this).toggleClass('active').next().slideToggle('slow')
      return false //Prevent the browser jump to the link anchor
    })

    $('.fromData').css('visibility', 'hidden')
    $('#box').animate({ top: '-700px' }, 500)
  },
  methods: {
    activator() {
      //   console.log('hi')
      $('#box').animate({ top: '0px' }, 500)
    },
    boxclose() {
      $('#box').animate({ top: '-700px' }, 500)
    },
    getSearch() {
      //   console.log('home input', this.inputSearch)
      Bus.$emit('input', this.inputSearch)
      this.inputSearch = ''
    },
  },
  created() {},
}
</script>
